/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c93c2762-914a-443a-b25b-ca7c13beba23",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_iLN2E6rWR",
    "aws_user_pools_web_client_id": "7r7q1cfpj3im5a4eahkrgee7vk",
    "oauth": {
        "domain": "championstoken.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": /* 'https://tokenchampions.com/profile/' */ 'https://test.tokenchampions.com/profile/' /* 'http://localhost:8080/profile/' */,
        "redirectSignOut": /* 'https://tokenchampions.com/profile/' */ 'https://test.tokenchampions.com/profile/' /* 'http://localhost:8080/profile/' */,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
