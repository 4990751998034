import React, {useState, useEffect, useContext} from 'react';/* 
import '../../Pages/Home/Home.css'; */
import awsConfig from '../../aws-exports';
import '../loginForm/LoginForm.css';
import './signIn.css';
import Loader from '../loader/Loader';
import { Link } from 'react-router-dom';
import {Amplify, Auth, Hub, I18n } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

/* import { useNavigate  } from 'react-router-dom'; */

import FooterSimple from '../layout/FooterSimple/FooterSimple';

import '@aws-amplify/ui-react/styles.css';

/* import awsExports from '../../aws-exports'; */
import { translations } from '@aws-amplify/ui-react';
import { useLocalStorage } from '../../useLocalStorage';

I18n.putVocabularies(translations);
I18n.setLanguage('es');

Amplify.configure(awsConfig);

/* Amplify.configure(awsExports); */


export default function SignIn( props ) {

  /* const authSta(tus = useAuthenticator(context => [context.authStatus]); */
  /* const { authStatus } = useAuthenticator(useAuthenticator.authStatus); */

  const isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
 /*  const [
    localRedirectSignIn,
    productionRedirectSignIn,
  ] = awsConfig.oauth.redirectSignIn.split(',');
  
  const [
    localRedirectSignOut,
    productionRedirectSignOut,
  ] = awsConfig.oauth.redirectSignOut.split(',');
  
  const updatedAwsConfig = {
    ...awsConfig,
    oauth: {
      ...awsConfig.oauth,
      redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
      redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
    }
  }
  
  Amplify.configure(updatedAwsConfig); */

  const [textSignIn, setTextSignIn] = useLocalStorage('textSignIn', 'CREA TU CUENTA');
  const [textSignInHidden, setTextSignInHidden] = useLocalStorage('textSignInHidden', '¿YA TIENES CUENTA? ENTRA AQUÍ PARA VER TU SALDO EN TOKENS');

  const [errorSignUp, setErrorSignUp] = useState('');
  const [txtErrorSignIn, setTxtErrorSignIn] = useState('');
  const [txtErrorConfirmCode, setTxtErrorConfirmCode] = useState('');
  const [user, setUser] = useState(null);

  const [email, setEmail] = useLocalStorage('email', '');
  const username = email;
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');

  const [flatSM, setFlatSM] = useState('');

  /* Social providers */

  const [banderaSSI, setBanderaSSI] = useState(null);


  const [userNamee, setUserNamee] = useLocalStorage('userNamee', '');
  const [code, setCode] = useState('');
  const [jwtTokenUser, setJwtTokenUser] = useLocalStorage('jwtTokenUser', '');

  const [errorMailtext, setErrorMailtext] = useState ('');
  const [errorMailtext2, setErrorMailtext2] = useState ('');

  const [dnone, setDNone] = useLocalStorage('dnone','');
  const [dnoneButtonSignIn, setDnoneButtonSignIn] = useLocalStorage('dnoneButtonSignIn', 'dnonee');
  const [dnoneButtonSignInGoogle, setDnoneButtonSignInGoogle] = useLocalStorage('dnoneButtonSignIn', 'dnonee');
  const [dnoneButtonSignInFacebook, setDnoneButtonSignInFacebook] = useLocalStorage('dnoneButtonSignIn', 'dnonee');
  const [dButtonReSendCode, setDButtonReSendCode] = useLocalStorage('dButtonReSendCode', 'dnonee');
  const [dnoneWelcomeScreen, setDnoneWelcomeScreen] = useLocalStorage('dnoneWelcomeScreen', 'dnonee');
  const [dnoneButtonChargeText, setDnoneButtonChargeText] = useLocalStorage('dnoneButtonChargeText', 'dflexx');
  const [dnoneSignUp, setDNoneSignUp] = useLocalStorage('dnoneSignUp','dnonee');
  const [dConfirmCode, setDConfirmCode] = useLocalStorage('dConfirmCode','dnonee');
  const [dTextError, setDTextError] = useState('dnonee');
  const [dTextError2, setDTextError2] = useState('dnonee');
  const [textErrorDef, setTextErrorDef] = useState('');

  const [dLoaderCreateAcc, setDLoaderCreateAcc] = useLocalStorage('dLoaderCreateAcc', 'dnonee');

  
  const [tokenTokeny, setTokenTokeny] = useLocalStorage('tokenTokeny','');

  const [txtuserOne, setTxtuserOne] = useLocalStorage('txtuserOne', '');
  const [txtuserTwo, setTxtuserTwo] = useLocalStorage('txtuserTwo', '');
  

  const myButtonAction = document.getElementById('myButtonAction');

  const [myButton, setMyButton] = useLocalStorage('myButton', 'dnonee');
  const [myButton2, setMyButton2] = useLocalStorage('myButton2', 'dnonee');

  const mailformat = /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
  const passwordFormat = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  /* const email = 'wapibob786@brandoza.com';
  const password = 'Test123!' */

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          /* setWithUE(); */
          setDNone('dnonee');
          setDnoneWelcomeScreen('dflexx');
          setTextSignIn('VER TU CUENTA');
          setTextSignInHidden('');
          getStatus('');
          setDnoneButtonSignInGoogle('dnonee');
          setDnoneButtonSignInFacebook('dnonee');
          window.location.reload();
          break;
        case 'signOut':
          /* setUser(null); */
          /* setDnoneButtonSignIn('dnonee');
          setEmail('');
          setPassword('');
          seteoVariables(); */
          setJwtTokenUser('');
          window.location.reload();
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then(userData => setUser(userData));
  }, []);

  useEffect(() => {
    /* setTxtuserOne('');
    setTxtuserTwo('');
    setMyButton('dnonee');
    setMyButton2('dnonee'); */
    /* setDnoneButtonChargeText('dflex'); */
    getStatus();
    setDTextError('dnonee');
  }, [jwtTokenUser]);

  const setWithUE = (() => {
    setTxtuserOne('');
    setTxtuserTwo('');
    setMyButton('dnonee');
    setMyButton2('dnonee');
    setDnoneButtonChargeText('dflex');
    getStatus();
    setDTextError('dnonee');
  });

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  const validateEmail = ((e) =>{
    setDTextError2('dnonee');
    setEmail(e.target.value);
    if (e.target.value === '') {
      setErrorMailtext('');
    } else {
        if (e.target.value.match(mailformat)) {
          setErrorMailtext('');
        }
        else {
          setErrorMailtext('La dirección de email que intentas introducir está mal escrita.');
        }
    }
  })
  const validatePassword = ((e) =>{
    setDTextError2('dnonee');
    setPassword(e.target.value);
    if (e.target.value === '') {
      setErrorMailtext2('');
    } else {
        if (e.target.value.match(passwordFormat)) {
          setErrorMailtext2('');
        }
        else {
          setErrorMailtext2('El password debe contener al menos una mayuscula, un numero y algun caracter.');
        }
    }
  })
  const cambiarASignUp = (() =>{
    setDNone('dnonee');
    setDNoneSignUp('dflexx');
  })
  const cambiarASignIn = (() =>{
    setDNone('dflexx');
    setDNoneSignUp('dnonee');
  })

  async function signIn() {
    setDnoneButtonSignIn('dflexx');
    setTxtErrorSignIn('');
    try {
        const user = await Auth.signIn(email, password);
        setDNone('dnonee');
        setDnoneWelcomeScreen('dflexx');
        setTextSignIn('VER TU CUENTA');
        setTextSignInHidden('');
        /* window.location.reload(); */
        getStatus();
        window.location.reload();
    } catch (error) {
        console.log('error signing in', error);
        setDnoneButtonSignIn('dnonee');
        setTxtErrorSignIn('La información que ingresaste no es valida, por favor verificala e intentalo de nuevo.');
    }
  }

  const SignOutTest = (() => {
    try {
        setDnoneButtonSignIn('dnonee');
        setEmail('');
        setPassword('');
        seteoVariables();
        Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  })

  async function signOut() {
    try {
        Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  /* async function signOut() {
    setDnoneButtonSignIn('dnonee');
    setEmail('');
    setPassword('');
    try {
        await Auth.signOut();
        seteoVariables();
    } catch (error) {
        console.log('error signing out: ', error);
    }
  } */

  const seteoVariables = (() => {
    setEmail('');
    setTokenTokeny('');
    setDNone('dflexx');
    setDnoneWelcomeScreen('dnonee');
    /* setJwtTokenUser(''); */
    setUserNamee('');
    setName('');
    setTxtuserOne('');
    setTxtuserTwo('');
    setMyButton('dnonee');
    setMyButton2('dnonee');
    setDnoneButtonChargeText('dflexx');
    setDConfirmCode('dnonee');
    setDNoneSignUp('dnonee');
    setDnoneButtonSignIn('dnonee');
    setCode('');
    setDTextError('dnonee');
    setTextErrorDef('');
    setTextSignIn('CREA TU CUENTA');
    setTextSignInHidden('¿YA TIENES CUENTA? ENTRA AQUÍ PARA VER TU SALDO EN TOKENS');
  });

  async function signUp() {
    setDnoneButtonSignIn('dflexx');
    try {
        const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
              email,          // optional
              name
          },
            autoSignIn: { // optional - enables auto sign in after user is confirmed
                enabled: true,
            }
        });
        setDNoneSignUp('dnonee');
        setDConfirmCode('dflexx');
        setDnoneButtonSignIn('dnonee');
        setDTextError2('dnonee');
    } catch (error) {
        setDnoneButtonSignIn('dnonee');
        setDTextError2('dflexx');
        console.log('error signing up:', error);
        setErrorSignUp(error.message);
    } 
  }

  function listenToAutoSignInEvent() {
    Hub.listen('auth', ({ payload }) => {
        const { event } = payload;
        if (event === 'autoSignIn') {
            const user = payload.data;
            // assign user
            setDConfirmCode('dnonee');
            setDnoneWelcomeScreen('dflexx');
            getStatus();
        } else if (event === 'autoSignIn_failure') {
          setDConfirmCode('dnonee');
          setDNoneSignUp('dflexx');
            // redirect to sign in page
        }
    })
  }

  async function confirmSignUp() {
    setDnoneButtonSignIn('dfleex');
    setTxtErrorConfirmCode('');
    try {
      await Auth.confirmSignUp(username, code);
      setDConfirmCode('dnonee');
      signIn();
      setDnoneButtonSignIn('dnonee');
    } catch (error) {
        setDnoneButtonSignIn('dnonee');
        setTxtErrorConfirmCode('El código que ingresaste es incorrecto, por favor verificalo e intentalo de nuevo.');
    }
  }

  async function resendConfirmationCode() {
    setDButtonReSendCode('dflexx');
    try {
        await Auth.resendSignUp(username);
        setDButtonReSendCode('dnonee');
    } catch (err) {
        console.log('error resending code: ', err);
    }
}


    function llamarToken() {
     Auth.currentSession().then(res=>{
      /* let accessToken = res.getAccessToken() */
      /* let refreshToken = res.getRefreshToken() */
      const IDToken= res.getIdToken()
      const setdujehjbsejvbsjev = `${JSON.stringify(IDToken.jwtToken)}`;
      setJwtTokenUser(setdujehjbsejvbsjev);
      setUserNamee(IDToken.payload.name);
      //You can print them to see the full objects
    })
  }

  const createUserTokeny = (async() => {
    setDLoaderCreateAcc('dflexx');
    await fetch('https://kulzy7cigh.execute-api.eu-west-3.amazonaws.com/test/tokeny/users', {
      method: 'POST',
      body: JSON.stringify({
        // Add parameters here
        'lang':'es'
      }),
      headers: {
        'Authorization': `Bearer ${jwtTokenUser.replace(/['"]+/g, '')}`,
      },
    })
    .then((response) => response.json())
    .then((data) => {
       // Handle data
    })
    .catch((err) => {
       console.log(err.message);
    });
    getTat();
  });

  const getTat = (async() => {
    await fetch('https://kulzy7cigh.execute-api.eu-west-3.amazonaws.com/test/tokeny/users/tat', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${jwtTokenUser.replace(/['"]+/g, '')}`,
      },
    })
    .then((res) => res.json())
    .then((data) => {
       setTokenTokeny(data.token);
       window.location.href = `https://tokenchampions-qualification-testing.tokeny.com/auth/sso?jwt=${data.token}`;
       setDLoaderCreateAcc('dnonee');
    })
    .catch((err) => {
       console.log(err.message);
    });
  });

  const getStatus = ( async () => {
    if (txtuserOne === '') {
      setDnoneButtonChargeText('dflexx');
     } else {
      setDnoneButtonChargeText('dnonee');
     }
    Auth.currentSession().then(res=>{
      const IDToken = res.getIdToken();
      setJwtTokenUser(`${JSON.stringify(IDToken.jwtToken)}`);
      const jwttokenusergetstatus = (`${JSON.stringify(IDToken.jwtToken)}`);
      setUserNamee(IDToken.payload.name);

      fetch('https://kulzy7cigh.execute-api.eu-west-3.amazonaws.com/test/tokeny/users/qualifications/status?lang=es', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${jwttokenusergetstatus.replace(/['"]+/g, '')}`,
        },
      })
      .then(response => {
        if (!response.ok) throw Error(response.status);
        return response;
      })
      .then((res) => res.json())
      .then((data) => {

         /* setDNone('dnonee');
         setDnoneWelcomeScreen('dflexx');
         setTextSignIn('VER TU CUENTA');
         setTextSignInHidden('');  */
 
         setDTextError('dnonee');
         setTxtuserOne(data.texto_1);
         setTxtuserTwo(data.texto_2);
         if (data.botoncompra===true) {
           setMyButton('dflexx');
         } else {
           setMyButton('dnonee');
         };
         if (data.botononbording===true) {
           setMyButton2('dflexx');
         } else {
           setMyButton2('dnonee');
         };
         setDnoneButtonChargeText('dnonee');
      })
      .then(response=> console.log(response))
      .catch((error) => {
         console.log(error.message);
         setDTextError('dflexx');
         setTextErrorDef(error.message);
         setDnoneButtonChargeText('dnonee');
      });
    })
  });

  async function sigInSocialMedia() {
    setDnoneButtonSignInGoogle('dflexx');
    setDnoneButtonSignIn('dnonee');
    try {
      Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google });
    } catch (error) {
      console.log('error signing out: ', error);
      setDnoneButtonSignInGoogle('dnonee');
    }
  }
  /* const sigInSocialMedia = (() => {
    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google });
  }) */
  async function sigInSocialMediaF() {
    setDnoneButtonSignInFacebook('dflexx');
    setDnoneButtonSignIn('dnonee');
    try {
      Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook });
    } catch (error) {
      console.log('error signing out: ', error);
      setDnoneButtonSignInFacebook('dnonee');
    }
  }


  return (
    <>
    <div className={`contContact`}>
      <div className='compHeadM'></div>
      <section className='homeSectNine1'>
          <div className='contForm'>
            <form className={`formLogin  ${dnoneSignUp}`}>
              <div className='contTitleLogo'>
                <div className='contLogoLogin'></div>
                <p className='contTitleFormLogin'>REGISTRO</p>
                <p className='contSubTitleFormLogin'>Bienvenido a Token Champions</p>
              </div>
              <br></br>
              <div className='contInputMailLogin'>
                <input onChange={e => setName(e.target.value)} value={name} className='inputfstSectFormLogin' placeholder='INGRESA TU NOMBRE:'></input>
              </div>
              <br></br>
              <div className='contInputMailLogin'>
                <input onChange={validateEmail} value={email} className='inputfstSectFormLogin' placeholder='INGRESA TU E-MAIL:'></input>
                <p className={`contName txterrorMail`}>{errorMailtext}</p>
              </div>
              <br></br>
              <div className='contInputMailLogin'>
                <input onChange={validatePassword} value={password} type='password' className='inputfstSectFormLogin' placeholder='INGRESA TU CONTRASEÑA:'></input>
                <p className={`contName txterrorMail`}>{errorMailtext2}</p>
              </div>
              <br></br>
              <p className={`errorMessageTxt ${dTextError2}`}>Se ha presentado el siguiente error: <span className='contName'> {errorSignUp}</span> </p>
              {/* <br></br>
                <button className='buttonRegGoogle'>INICIAR SESIÓN CON GOOGLE</button>
                <button className='buttonRegFB'>INICIAR SESIÓN CON FACEBOOK</button>
              <br></br> */}
              <div className='contButtonSendFormLogin'>
                <div onClick={signUp} className='btnSendFormLogin1'>REGISTRARSE<Loader classNm={dnoneButtonSignIn}/></div>
              </div>
              <div className='contButtonSendFormLogin mtop'>
                <a className='btnSendFormLoginFB' onClick={sigInSocialMediaF}>REGISTRATE CON FACEBOOK <Loader classNm={dnoneButtonSignInFacebook}/> </a>
              </div>
              <div className='contButtonSendFormLogin mtop'>
                <a className='btnSendFormLoginGoogle' onClick={sigInSocialMedia}>REGISTRATE CON GOOGLE <Loader classNm={dnoneButtonSignInGoogle}/> </a>
              </div>
              <div className='contButtonSendFormLogin mtop'>
                <button className='btnSendFormLogin1' onClick={cambiarASignIn} >INICIAR SESIÓN</button>
              </div>
            </form>
            <form className={`formLogin ${dnone}`}>
              <div className='contTitleLogo'>
                <div className='contLogoLogin'></div>
                <p className='contTitleFormLogin'>INICIAR SESIÓN</p>
                <p className='contSubTitleFormLogin'>Bienvenido a Token Champions</p>
              </div>
              <br></br>
              <div className='contInputMailLogin'>
                <input onChange={e => setEmail(e.target.value)} className='inputfstSectFormLogin' placeholder='INGRESA TU E-MAIL:'></input>
              </div>
              <br></br>
              <div className='contInputMailLogin'>
                <input onChange={e => setPassword(e.target.value)} type='password' className='inputfstSectFormLogin' placeholder='INGRESA TU CONTRASEÑA:'></input>
              </div>
              <br></br>
              <p className='txtErrorSI'>{txtErrorSignIn}</p>
              <div className='contButtonSendFormLogin'>
                <a onClick={signIn} className='btnSendFormLogin1'>INICIAR SESIÓN <Loader classNm={dnoneButtonSignIn}/></a>
              </div>
              <div className='contButtonSendFormLogin mtop'>
                <a className='btnSendFormLoginFB' onClick={sigInSocialMediaF}>INICIAR SESIÓN CON FACEBOOK <Loader classNm={dnoneButtonSignInFacebook}/> </a>
              </div>
              <div className='contButtonSendFormLogin mtop'>
                <a className='btnSendFormLoginGoogle' onClick={sigInSocialMedia}>INICIAR SESIÓN CON GOOGLE <Loader classNm={dnoneButtonSignInGoogle}/> </a>
              </div>
              <div className='contButtonSendFormLogin mtop'>
                <button className='btnSendFormLogin1' onClick={cambiarASignUp} >REGISTRARSE</button>
              </div>
              
              
            </form>
            <div className={`formLogin dflexx ${dnoneWelcomeScreen}`}>
              <div className='contTitleLogo'>
                <div className='contLogoLogin'></div>
                  <br></br>
                  <h1 className='txth1title'>Bienvenido <span className='contName'>{userNamee} </span><br></br></h1>
                  <br></br>
                  <p className={`txth1subtitle ${dTextError}`}>{`Se ha producido el siguiente error: `}<span className='contName mleft'> {textErrorDef}</span></p>
                  <Loader classNm={dnoneButtonChargeText}/>
                  <p className='txth1subtitle'>{txtuserOne}</p>
                  <br></br>
                  <p className='txth1subtitle'>{txtuserTwo}</p>
                  <br></br>
                  <div className='contBtnSellQuality'>
                    <div className='btnCreateAccountLink1'>
                      <button onClick={createUserTokeny}  className={`btnSendFormLogin2 ${myButton2}`}>CALIFICARSE COMO INVERSIONISTA<Loader classNm={dLoaderCreateAcc}/></button>
                    </div>
                    <Link className='btnCreateAccountLink1' to='/tokenSell'>
                      <button className={`btnSendFormLogin2 ${myButton}`}>COMPRAR TOKEN</button> 
                    </Link>
                    <div className='btnCreateAccountLink1'>
                      <a className='btnSendFormLogin1 mtop' onClick={SignOutTest} >CERRAR SESIÓN</a>
                    </div>
                  </div>
                </div>
            </div>
            <div className={`formLogin ${dConfirmCode}`}>
              <div className='contTitleLogo'>
                <div className='contLogoLogin'></div>
                <br></br>
                <p className='txth1title1'>Por favor ingresa el código enviado al mail:<br></br> <span className='contName'>{username}</span><br></br></p>
                <br></br>
                <input onChange={e => setCode(e.target.value)} value={code} className='amountTKCH1' type="Number"></input>
                <br></br>
                <p className='txtErrorSI'>{txtErrorConfirmCode}</p>
                <button onClick={confirmSignUp}  className={`btnSendFormLogin1 mbtm`}>ENVIAR CONFIRMACIÓN<Loader classNm={dnoneButtonSignIn}/></button>
                <button onClick={resendConfirmationCode}  className={`btnSendFormLogin1`}>RE ENVIAR CÓDIGO<Loader classNm={dButtonReSendCode}/></button>
              </div>
            </div>
          </div>
        </section>
    </div>
    {/* <div className='ndfibsfbjerbgjk'>{userNamee}</div>
    <button className='btnSendFormLogin1 mtop' onClick={signOut2} >CERRAR SESIÓN</button>
    <button className='btnSendFormLogin1 mtop' onClick={getStatus} >get status</button> */}
    <FooterSimple />
    </>
  );
}