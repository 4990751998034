/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react';
import './tokenSell.css';
import FooterSimple from '../layout/FooterSimple/FooterSimple';
import Loader from '../loader/Loader';


export default function TokenSell() {

  const email = localStorage.getItem('email');
  const jwtTokenUser = localStorage.getItem('jwtTokenUser');

  const [jwtPayIntention, setJwtPayIntention] = useState('');

  const [paymentMethod, setPaymentMethod ] = useState('');

  const [dnoneButtonSignIn, setDnoneButtonSignIn] = useState('dnonee');

  const [amountTKCH, setAmountTKCH] = useState(50);

  const [txtInvalidAmount, setTxtInvalidAmount] = useState('');
  const [txtInvalidAmount2, setTxtInvalidAmount2] = useState('');
  const [txtInvalidAmount3, setTxtInvalidAmount3] = useState('');

  const [classActivePaymentMethods, setClassActivePaymentMethods] = useState('contThreePaymentMet2');

  useEffect(() => {
    const jwtPayInt = jwtTokenUser.replace(/"+/g, '');
    setJwtPayIntention(jwtPayInt.replace(/\\+/g, ''));
  }, []);

  const amountEurosprev = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
    })
  /* const [amountEuros, setAmountEuros] = useState(100); */

  /* const convertion = amountTKCH */
  
  /* const amountEuros = amountTKCH*2 */

  const amountEuros = amountEurosprev.format(amountTKCH*2)

  const validAmount = ((e)=>{
    setTxtInvalidAmount2('');
    if(e.target.value<50){
        setTxtInvalidAmount('El monto ingresado es menor al mínimo.');
        /* setClassActivePaymentMethods('contThreePaymentMet'); */
        /* myButtonAmount.disabled = true;
        myButtonAmount2.disabled = true;
        myButtonAmount3.disabled = true; */
    } else {
        setTxtInvalidAmount('');
        /* setClassActivePaymentMethods('contThreePaymentMet2'); */
        /* myButtonAmount.disabled = false;
        myButtonAmount2.disabled = false;
        myButtonAmount3.disabled = false; */
        
    }
    setAmountTKCH(e.target.value);
    
  })

  const vermail = (() => {
    setDnoneButtonSignIn('dflexx');
    if (paymentMethod === '') {
      setTxtInvalidAmount2('No has seleccionado ningun metodo de pago');
      setDnoneButtonSignIn('dnonee');
    } else if (amountTKCH < 50){
      setTxtInvalidAmount2('Por favor corrija el monto a uno permitido para continuar.');
      setDnoneButtonSignIn('dnonee');
    } else {
      setTxtInvalidAmount2('');
      createPayIntention();
    }
    /* createPayIntention(); */
  })

  const validarValor = (() => {
    setPaymentMethod('tc');
    setTxtInvalidAmount2('');
  })
  const validarValor2 = (() => {
    setPaymentMethod('deposito');
    setTxtInvalidAmount2('');
  })

  const createPayIntention = (async() => {
    await fetch(`https://kulzy7cigh.execute-api.eu-west-3.amazonaws.com/test/tccore/payments/${btoa(email)}/intention`, {
      method: 'POST',
      body: JSON.stringify({
        // Add parameters here
        'lang':'es',
        "properties": {
          "formadepago": paymentMethod,
          "montoEU": amountTKCH,
        }
      }),
      headers: {
        'Authorization': `Bearer ${jwtPayIntention}`,
      },
    })
    .then(response => {
      if (!response.ok) throw Error(response.status);
      return response;
    })
    .then((response) => response.json())
    .then((data) => {
       // Handle data
       setAmountTKCH('');
       setPaymentMethod('');
       setTxtInvalidAmount2(`La solicitud se realizó con exito, el ID de su operación es: ${data.operation_id} `);
       setDnoneButtonSignIn('dnonee'); 
    })
    .catch((error) => {
      console.log(error.message);
      setTxtInvalidAmount2(`Ha ocurrido el siguiente error: ${error.message} `);
      setDnoneButtonSignIn('dnonee');
    });
  });


  return (
    <div className='contTokenSell'>
        <div className='compHeadM'></div>
        <section className='sectOneTokenSell'>
            <div className='contLogoTKS'></div>
        </section>
        <section className='sectTwoTokenSell'>
            <p className='txtHowManyTkch'>¿CUÁNTOS TKCH TE GUSTARÍA COMPRAR?</p>
            <br></br>
            <div className='contCalc'>
                <div className='contTwoParts'>
                    <div className='contLRTS'>
                        <p className='txtCalc'>EUR</p>
                        <br></br>
                        <p className='txtCalc txtCalc2'>TKCH</p>
                    </div>
                    <div className='contLRTS'>
                        <p className='txtCalc'>{`${amountEuros}`}</p>
                        <br></br>
                        <input onChange={validAmount} value={amountTKCH} className='amountTKCH' type="Number"></input>
                    </div>
                </div>
                <p className='txtError'>{txtInvalidAmount}</p>
            </div>
            <br></br>
            <p className='txtHowManyTkch2'>SELECCIONA UN METODO DE PAGO</p>
            <br></br>
            <div className={classActivePaymentMethods}>
                <div className='continputPI'>
                  <input onClick={validarValor} className='inputPayIntention' type="radio" id="" name="fav_language" value={paymentMethod}></input>
                  <div id='myButtonAmountqq' className='widthCont contOneMetP'></div>
                  <div id='myButtonAmount2qq' className='widthCont conTwoMetP'></div>
                </div>
                <div className='continputPI'>
                  <input onClick={validarValor2} className='inputPayIntention' type="radio" id="" name="fav_language" value=""></input>
                  <div id='myButtonAmount3qq' className='widthCont contThreeMetP linktoDB'>Deposito Bancario</div>
                </div>
            </div>
            <p className='txtError'>{txtInvalidAmount2}</p>
            <p className='txtError'>{txtInvalidAmount3}</p>
            <br></br>
            <button onClick={vermail} className="btnSendFormLogin3">COMPRAR <Loader classNm={dnoneButtonSignIn}/></button> 
        </section>
        <FooterSimple />
    </div>
  )
}